import Error404 from "../../json/pages/404.json";
import AssemblyGuide from "../../json/pages/AssemblyGuide.json";
import Breadcrumbs from "../../json/pages/Breadcrumbs.json";
import BuyGuide from "../../json/pages/BuyGuide.json";
import BuyInfo from "../../json/pages/BuyInfo.json";
import Campaign from "../../json/pages/Campaign.json";
import CheckoutDeliveryInfo from "../../json/pages/CheckoutDeliveryInfo.json";
import CheckoutOtherOptions from "../../json/pages/CheckoutOtherOptions.json";
import CheckoutPlaceOrder from "../../json/pages/CheckoutPlaceOrder.json";
import CheckoutPlaceOrderSuccess from "../../json/pages/CheckoutPlaceOrderSuccess.json";
import CheckoutRelatedProducts from "../../json/pages/CheckoutRelatedProducts.json";
import CheckoutShipmentPayment from "../../json/pages/CheckoutShipmentPayment.json";
import CheckoutShoppingCart from "../../json/pages/CheckoutShoppingCart.json";
import CheckoutSubtotal from "../../json/pages/CheckoutSubtotal.json";
import CookiePolicy from "../../json/pages/CookiePolicy.json";
import CustomMade from "../../json/pages/CustomMade.json";
import CustomerService from "../../json/pages/CustomerService.json";
import FooterLinkSections from "../../json/pages/FooterLinkSections.json";
import MarqueeWidget from "../../json/pages/MarqueeWidget.json";
import ImageFootnoteCardDeck from "../../json/pages/ImageFootnoteCardDeck.json";
import Imprint from "../../json/pages/Imprint.json";
import IntegrityPolicy from "../../json/pages/IntegrityPolicy.json";
import LogoList from "../../json/pages/LogoList.json";
import MainMenu from "../../json/pages/MainMenu.json";
import MenuBarHeader from "../../json/pages/MenuBarHeader.json";
import PaymentMethods from "../../json/pages/PaymentMethods.json";
import ProductBrandInfo from "../../json/pages/ProductBrandInfo.json";
import ProductSeries from "../../json/pages/ProductSeries.json";
import SearchBanner from "../../json/pages/SearchBanner.json";

export default (siteId, config, mergeSiteConfig) => {
  const obj = {
    AssemblyGuide,
    Breadcrumbs,
    BuyGuide,
    BuyInfo,
    Campaign,
    CheckoutDeliveryInfo,
    CheckoutOtherOptions,
    CheckoutPlaceOrder,
    CheckoutPlaceOrderSuccess,
    CheckoutRelatedProducts,
    CheckoutShipmentPayment,
    CheckoutShoppingCart,
    CheckoutSubtotal,
    CookiePolicy,
    CustomerService,
    CustomMade,
    Error404,
    FooterLinkSections,
    MarqueeWidget,
    ImageFootnoteCardDeck,
    Imprint,
    IntegrityPolicy,
    LogoList,
    MainMenu,
    MenuBarHeader,
    PaymentMethods,
    ProductBrandInfo,
    ProductSeries,
    SearchBanner
  };

  return mergeSiteConfig(siteId, config, obj, "frontend/i18n/pages");
};
